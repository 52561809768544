import {AfterViewInit, Component, OnInit} from '@angular/core';
import {EmpresasService} from "../../../superadmin/services/empresas.service";
import {ConstantsService} from "../../ConstantsService";
import {TemaPersonalizadoService} from "../../../services/tema-personalizado.service";
import { Router } from '@angular/router';

interface Tema {
  id?: string;
  nome: string;
  tipo: 'personalizado' | 'comemorativo';
  codigo?: string;
  imagem?: string;
  novo?: boolean;
  atualizado?: boolean;
}

@Component({
  selector: 'app-tela-configuracao-temas',
  templateUrl: './tela-configuracao-temas.component.html',
  styleUrls: ['./tela-configuracao-temas.component.scss']
})
export class TelaConfiguracaoTemasComponent implements OnInit {
  empresa: any;
  ativando = false;
  temasPersonalizados: any[] = [];
  filtroAtual: 'todos' | 'personalizados' | 'comemorativos' = 'todos';

  // Lista completa de temas do sistema
  temasDoSistema: Tema[] = [
    { nome: 'Clássico', codigo: 'meucardapioclassico', tipo: 'comemorativo', imagem: 'tema_meucardapioclassico.png' },
    { nome: 'Carnaval 2024', codigo: 'carnaval-2024', tipo: 'comemorativo', novo: true, imagem: 'tema-carnaval-2024.jpeg' },
    { nome: 'Copa 2022', codigo: 'copa_2022', tipo: 'comemorativo', imagem: 'tema_copa_2022.png' },
    { nome: 'Black Friday', codigo: 'black_friday', tipo: 'comemorativo', imagem: 'tema_black_friday.png' },
    { nome: 'Black Friday 2022', codigo: 'black_friday2022', tipo: 'comemorativo', imagem: 'tema_black_friday2022.png' },
    { nome: 'Dia das Mães', codigo: 'dia_maes', tipo: 'comemorativo', imagem: 'tema_dia_maes.png' },
    { nome: 'Dia dos Pais', codigo: 'dia_dos_pais', tipo: 'comemorativo', imagem: 'tema_dia_dos_pais.png' },
    { nome: 'Black', codigo: 'black', tipo: 'comemorativo', imagem: 'tema_black.png' },
    { nome: 'Páscoa', codigo: 'pascoa', tipo: 'comemorativo', imagem: 'tema_pascoa.png' },
    { nome: 'Natal', codigo: 'natal', tipo: 'comemorativo', imagem: 'tema_natal.png' },
    { nome: 'Ano Novo', codigo: 'ano_novo', tipo: 'comemorativo', atualizado: true, imagem: 'tema_ano_novo.png' },
    { nome: 'Arraiá', codigo: 'arraia', tipo: 'comemorativo', imagem: 'tema_arraia.png' },
    { nome: 'Dia dos Namorados', codigo: 'dia_namorados', tipo: 'comemorativo', imagem: 'tema_dia_namorados.png' }
  ];

  temasFiltrados: (Tema | any)[] = [];

  constructor(
    private empresasService: EmpresasService,
    private constantsService: ConstantsService,
    private temaService: TemaPersonalizadoService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.constantsService.empresa$.subscribe((empresa: any) => {
      if (!empresa) return;
      this.empresa = empresa;
    });
    this.carregarTemasPersonalizados();
  }

  carregarTemasPersonalizados() {
    this.temaService.listeTemas().then(temas => {
      this.temasPersonalizados = temas.map(tema => ({
        ...tema,
        tipo: 'personalizado'
      }));
      this.aplicarFiltro('todos');
    });
  }

  aplicarFiltro(filtro: 'todos' | 'personalizados' | 'comemorativos') {
    this.filtroAtual = filtro;

    let temasFiltrados: (Tema | any)[] = [];

    // Sempre inclui os temas personalizados se existirem
    if (filtro === 'todos' || filtro === 'personalizados') {
      temasFiltrados = [...this.temasPersonalizados];
    }

    // Filtra os temas do sistema de acordo com o filtro selecionado
    const temasDoSistemaFiltrados = this.temasDoSistema.filter(tema => {
      if (filtro === 'todos') return true;
      if (filtro === 'comemorativos') return tema.tipo === 'comemorativo';
      return false;
    });

    this.temasFiltrados = [...temasFiltrados, ...temasDoSistemaFiltrados];
  }

  ativarTemaPersonalizado(tema: any) {
    this.ativando = true;

    this.empresasService.ativarTema(this.empresa.id, tema.id).then(() => {
      this.ativando = false;
      this.empresa.temaPersonalizado = tema;
      this.empresa.tema = 'tema-personalizado';
    }).catch((erro) => {
      this.ativando = false;
      console.error('Erro ao ativar tema:', erro);
    });
  }

  ativarTema(temaCodigo: string) {
    this.ativando = true;

    this.empresasService.ativarTema(this.empresa.id, temaCodigo).then(() => {
      this.empresa.temaPersonalizado = null;
      this.ativando = false;
      this.empresa.tema = temaCodigo;
    }).catch((erro) => {
      this.ativando = false;
      console.error('Erro ao ativar tema:', erro);
    });
  }

  desativarTema() {
    this.ativando = true;

    this.empresasService.ativarTema(false, '').then(() => {
      this.ativando = false;
      this.empresa.tema = null;
      this.empresa.temaPersonalizado = null;
    }).catch((erro) => {
      this.ativando = false;
      console.error('Erro ao desativar tema:', erro);
    });
  }

  editarTema(tema: any) {
    this.router.navigate(['/admin/temas/criar/', tema.id]);
  }

  criarTemaPersonalizado() {
    this.router.navigate(['/admin/temas/criar']);
  }

  isTemaAtivo(tema: any): boolean {
    if (tema.tipo === 'personalizado') {
      return this.empresa.temaPersonalizado?.id === tema.id;
    }
    if (tema.codigo === 'meucardapioclassico') {
      return !this.empresa.tema || this.empresa.tema === 'meucardapioclassico';
    }
    return this.empresa.tema === tema.codigo;
  }

  handleImageError(event: Event, imagem: string) {
    const img = event.target as HTMLImageElement;
    if (img.src.endsWith('.png')) {
      img.src = `/assets/loja/${imagem.replace('.png', '.jpg')}`;
    } else if (img.src.endsWith('.jpg')) {
      img.src = `/assets/loja/${imagem.replace('.png', '.jpeg')}`;
    }
  }
}
