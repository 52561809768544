<div class="card mt-4" style="max-width: 800px;">
  <div class="modal-header">
    <button class="btn-voltar btn btn-outline-light btn-rounded" (click)="voltar()">
      <i class="fa fa-arrow-left ct-point" ></i>
    </button>
    <h4 class="modal-title" id="myModalLabel">
      <span>
          <i class="fas fa-utensils" style="font-size: 22px;"></i> {{tituloPagina}} <i class="fas fa-beer fa-lg"></i>
      </span>
    </h4>
  </div>

  <div class="card-body">
    <app-tela-mesa-escolhida #telaMesaEscolhida></app-tela-mesa-escolhida>

    <form [ngClass]="{'needs-validation': !frm.submitted, 'was-validated': frm.submitted}"
          novalidate #frm="ngForm" (ngSubmit)="onSubmit()" >

      <div class="row mb-2"  *ngIf="pedido.totalResgatado">
        <div class="col">
          <div class="form-group ">
            <label>Total resgatar</label>
            <div class="mt-0 mb-1 font-24 valor">{{pedido.totalResgatado  }} pts</div>

          </div>

          <div class="form-group  "  >
            <input id="resgatar" name="resgatar" type="checkbox" required disabled
                   [(ngModel)]="pedido.resgate.usar"      class="k-checkbox" kendoCheckBox   />
            <label for="resgatar" class="ml-1"  >
              Usar <b>pontos</b> no resgate, pontos disponíveis <span class="text-primary font-18"><b>{{ obtenhaSaldoCartao() }} pts</b></span>
            </label>
          </div>

          <p *ngIf="pedido.resgate.saldoInsuficente " class="text-danger">
            <b>Pontos são insuficientes para o resgate</b>
          </p>

        </div>

      </div>


      <div class="row">
        <div class="col">
          <div class="form-group ">
            <label>Total Pedido</label>
            <div class="mt-0 mb-1 font-24 valor">{{pedido.obtenhaTotalPedidoSemTaxaForma()  | currency: 'BRL' }}</div>
          </div>
        </div>
        <div class="col">
          <div class="form-group">
            <label>Total restante a pagar</label>
            <div class="mt-0 mb-1 font-24 valor">{{pedido.obtenhaValorRestanteAhPagar() | currency: 'BRL' }}</div>
          </div>
        </div>
      </div>

      <div class="row  "   >
        <div class="form-group col col-sm-6">
          <label>Desconto</label>

          <div class="input-group">
            <kendo-numerictextbox name="valorFinal"   format="c2" appRemovaWheel  class="form-control"
                                  [(ngModel)]="pedido.desconto" [min]="0"  (ngModelChange)="recalculeTotal()"  >
            </kendo-numerictextbox>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="form-group col col-sm-6">
          <div  style="cursor: pointer">
            <i class="fas fa-ticket-alt mr-1 fa-lg"  ></i>
            <label>Adicione um código promocional</label>

          </div>
          <div class="input-group mb-2">
            <input type="text" class="form-control" name="codigoCupom" placeholder="Código do cupom"
                   [(ngModel)]="pedido.codigoCupom" [disabled]="aplicandoCupom"  >
            <div class="input-group-append">
              <button class="btn  btn-outline-blue" type="button" (click)="apliqueCupom()">Aplicar</button>
            </div>
          </div>
          <div *ngIf="erroCupom">
            <h6 class="alert alert-danger d-block" style="padding: 5px;" >
              <i class="fas fa-exclamation-triangle fal-lg ml-1 mr-1 "></i>
              {{erroCupom}}
            </h6>
          </div>

        </div>

      </div>



      <div  *ngIf="pedido.cashback && obtenhaSaldoCartao() > 0"  >

        <h6 class="alert alert-success d-block" style="padding: 5px;">
          <i class="fa fa-check   fal-lg ml-1 mr-1 "></i>
          Cliente tem cashback acumulado
        </h6>

        <div  class="row">
          <div   class="col "  [hidden]="usarSaldo" *ngIf=" !this.pedido.cashback.formaDePagamento">
            <p  class="text-muted font-12">
              Habilite a forma de pagamento em cashback para usar o saldo como forma de pagamento.
            </p>
          </div>
          <div   class="col col-sm-6 "  [hidden]="usarSaldo"  *ngIf=" this.pedido.cashback.formaDePagamento">

            <button type="button" class="btn btn-outline-dark btn-block mb-2" (click)="usarSaldoCartao()"
                    *ngIf=" this.pedido.cashback.formaDePagamento">
              Usar saldo <b>{{ obtenhaSaldoCartao() | currency:"BRL"}}</b></button>
          </div>

          <div class="col"  *ngIf="usarSaldo">
            <div class="row" *ngIf="this.pedido.cashback.id">
              <div class="form-group col col-sm-6 " >
                <label>Cashback utlizado nessa pedido: <b>{{pedido.cashback.valor | currency: "BRL"}}</b>

                </label>
              </div>
            </div>
            <div class="row" *ngIf="!this.pedido.cashback.id">
              <div class="form-group col col-sm-6 " >
                <label>Quanto do cashback vai usar?</label>

                <div class="input-group">
                  <kendo-numerictextbox id="saldoUsar" name="saldoUsar" #saldoUsar=ngModel #saldoUsarText [(ngModel)]="pedido.cashback.valor"
                                        format="c2" class="form-control"
                                        (ngModelChange)="recalculeComCashback()"  [max]="maximoCashback" [min]="0"
                                        [required]="usarSaldo">

                  </kendo-numerictextbox>

                  <div class="invalid-feedback" >
                    <span *ngIf="saldoUsar.errors?.required">Informe  o saldo a ser utilizado </span>
                    <span *ngIf="saldoUsar.errors?.maxError">Valor  máximo é  {{saldoUsar.errors.maxError.maxValue   | currency: "BRL"}} </span>
                    <span *ngIf="saldoUsar.errors?.minError">Valor  mínimo é  {{saldoUsar.errors.minError.minValue  | currency: "BRL"}} </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


      </div>

      <div class="mb-1"  [hidden]="!pedido.total || pedido.resgate?.saldoInsuficente">
        <div class="row">
          <div class="col">
            <div  class="form-group">

              <app-grid-pagamento #gridPagamentos  [frm]="frm" (mudouFormaPagamento)="mudouFormaPagamento()"
                                  (alterouOpcaoMultiplasFormas)="alterouOpcaoMultiplasFormas()"
                                  [formasDePagamento]=" formasDePagamento" [totalPagar]="pedido.obtenhaValorAhPagar()"
                                  [totalProdutos]="pedido.obtenhaValorAhPagarProdutos()" >

              </app-grid-pagamento>


              <div *ngIf="msgErroAdicionar">
                <h6 class="alert alert-danger d-block" style="padding: 5px;" >
                  <i class="fas fa-exclamation-triangle fal-lg ml-1 mr-1 "></i>
                  {{msgErroAdicionar}}
                </h6>
              </div>

            </div>
          </div>
        </div>

      </div>

      <div class="form-group mb-3">
        <label for="observacoes">Observações:</label>
        <input type="text" class="form-control" autocomplete="off"
               id="observacoes" name="observacoes" [(ngModel)]="pedido.observacoes" #txtObservacoes="ngModel"
               placeholder="Observações sobre o pedido" value="">
        <div class="invalid-feedback">
          <p *ngIf="txtObservacoes.errors?.required">Informe observações do pedido</p>
        </div>
      </div>


      <div class="mt-3">
        <button class="btn btn-blue btn-block" type="submit">Checar Pedido</button>
      </div>
    </form>
  </div>
</div>

