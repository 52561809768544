import {Component, Inject, OnInit, Renderer2, ViewChild} from '@angular/core';
import {ConstantsService} from "../../fidelidade/ConstantsService";
import {FormaDePagamento} from "../../pedidos/objetos/FormaDePagamento";
import {EditEvent, GridDataResult, RemoveEvent} from "@progress/kendo-angular-grid";
import {DialogService} from "@progress/kendo-angular-dialog";
import {NovaFormaDePagamentoComponent} from "../nova-forma-de-pagamento/nova-forma-de-pagamento.component";
import {FormasDePagamentoService} from "../../services/formas-de-pagamento.service";
import { GroupDescriptor, DataResult, process } from '@progress/kendo-data-query';
import {DOCUMENT} from "@angular/common";
import {ActivatedRoute} from "@angular/router";
import {AutorizacaoService} from "../../services/autorizacao.service";
declare  var Hub;

@Component({
  selector: 'app-cadastro-formas-de-pagamento',
  templateUrl: './cadastro-formas-de-pagamento.component.html',
  styleUrls: ['./cadastro-formas-de-pagamento.component.scss']
})
export class CadastroFormasDePagamentoComponent implements OnInit {
  @ViewChild('pagarmehub',  {static: false} ) pagarmehub: any;
  loading: any;
  empresa: any;
  formasDePagamento: Array<FormaDePagamento>
  public gridDataResult: GridDataResult;
  carregando: any;
  modalDeletar = false;
  filtro: any;
  formaApagar: FormaDePagamento;
  pagamentos: Array<{ valor: boolean, descricao: string }> = [
    { valor: false, descricao: 'Pagamento Na Entrega'  },
    { valor: true, descricao: 'Pagamento Online' }
  ];
  public groups: GroupDescriptor[] = [{ field: 'tipoDePagamento' }];
  confirmarMigrar = false;
  migrandoFormasPagamento = false;
  usuario: any = {};
  constructor(private constantsService: ConstantsService, private dialogService: DialogService,
              private _renderer2: Renderer2,  @Inject(DOCUMENT) private  _document: Document,
              private  activatedRoute: ActivatedRoute, private autorizacaoService: AutorizacaoService,
              private formasDePagamentoService: FormasDePagamentoService) { }

  ngOnInit(): void {
    this.addScriptHugPagarme();

    this.constantsService.empresa$.subscribe( (empresa) => {
      if( !empresa ) return;

      this.empresa = empresa;

      this.formasDePagamentoService.obtenhaFormasDePagamentos(this.empresa).then( (formas) => {
        this.formasDePagamento = formas;

        for( let forma of this.formasDePagamento ) {
          if(forma.formaDePagamentoPdv && forma.formaDePagamentoPdv.tipo === 'FIDELIDADE' )
            forma.tipoDePagamento = 'Fidelidade'
          else
            forma.tipoDePagamento = forma.online ? 'Pagamento Online' : 'Pagamento Na Entrega';
        }

        this.gridDataResult = process(this.formasDePagamento, { group: this.groups });
      });

      if(!empresa.integracaoGatewayPagamento || empresa.integracaoGatewayPagamento.gateway === 'pagarme')
        this.configureHubPargarme(empresa.integracaoGatewayPagamento ? empresa.integracaoGatewayPagamento.instalacaoId : null);
    });

    this.usuario = this.autorizacaoService.getUser() || {};
  }

  private addScriptHugPagarme() {
    let script = this._renderer2.createElement('script');
    script.type = 'text/javascript';
    script.src =  '/assets/js/hub.min.js';
    this._renderer2.appendChild(this._document.body, script);
  }


  configureHubPargarme(installId: string = null){
    if(typeof Hub === 'function' && this.pagarmehub){
      let redirectUrl =  String(`${window.location.origin}/admin/loja/retorno/pagarme`)
      let config: any = {
        publicAppKey : "21a6596d-5eeb-4324-9dc2-c5742d5db0d1",
        redirectUrl : redirectUrl , // "https://my-service.com/callback",
        language: "pt-BR",
        environment: "production", // "production", "dev"
      };

      if(installId)
        config.installId = installId;

      Hub(config);

      //button disparando form do angular pq esta sem tipo
      this.marqueTipoBotaoInserido();
    } else {
      setTimeout(() => {
        console.log('aguardando scripts/tela: scripts: ' + (typeof Hub) + '-> tela: ' + (this.pagarmehub != null) )
        this.configureHubPargarme(installId)
      }, 1000);
    }

  }

  marqueTipoBotaoInserido(){
    const buttonInsideSpan = this.pagarmehub.nativeElement.querySelector('button');
    if (buttonInsideSpan) {
      buttonInsideSpan.setAttribute('type', 'button');
    } else {
      setTimeout(() => {
        this.marqueTipoBotaoInserido()
      }, 1000)
    }
  }

  abraModalMigrarFormasBandeira(){
    this.confirmarMigrar = true;
  }


  confirmarMigrarBanderias(){
    this.migrandoFormasPagamento = true;

    this.formasDePagamentoService.migreParaBandeiras().then(() => {
      this.migrandoFormasPagamento  = false;
      this.confirmarMigrar = false;
      window.location.assign('/admin/loja/formaspagamento/offline');
    }).catch((err) => {
      this.migrandoFormasPagamento  = false;
      alert(err)
    })
  }

  editar($event: EditEvent) {
    const forma: FormaDePagamento = $event.dataItem;

    let altura = window.innerHeight - 100;

    const windowRef = this.dialogService.open({
      title: 'Cadastro de Forma de Pagamento',
      content: NovaFormaDePagamentoComponent,
      minWidth: 250,
      width: window.innerWidth > 600 ? 600 : window.innerWidth,
      maxHeight: altura
    });

    const telaNovaFormaDePagamento: NovaFormaDePagamentoComponent = windowRef.content.instance;

    telaNovaFormaDePagamento.windowRef = windowRef;
    telaNovaFormaDePagamento.editar(forma, this.empresa);

    windowRef.result.subscribe((result: any) => {
      this.constantsService.recarregueEmpresa();
    });
  }

  abraDialogRemover($event: RemoveEvent) {
    this.formaApagar = $event.dataItem;

    this.modalDeletar = true;
  }

  onFilter($event: Event) {

  }

  onPagamentoChange(selected: { valor: boolean, descricao: string }) {
    this.novo(selected.valor);
  }

  novo(pagamentoOnline: boolean) {
    let altura = window.innerHeight - 100;

    const windowRef = this.dialogService.open({
      title: 'Cadastro de Forma de Pagamento',
      content: NovaFormaDePagamentoComponent,
      minWidth: 250,
      width: window.innerWidth > 600 ? 600 : window.innerWidth,
      maxHeight: altura
    });

    const telaNovaFormaDePagamento: NovaFormaDePagamentoComponent = windowRef.content.instance;
    telaNovaFormaDePagamento.novaForma(pagamentoOnline, this.empresa);
    telaNovaFormaDePagamento.windowRef = windowRef;

    windowRef.result.subscribe((result: any) => {
      this.constantsService.recarregueEmpresa();
    });
  }

  close(status: string) {
    if( status === 'sim') {
      this.formasDePagamentoService.removaFormaDePagamento(this.formaApagar).then( () => {
        this.constantsService.recarregueEmpresa();
        this.formaApagar = null;
        this.modalDeletar = false;
      });
    } else {
      this.formaApagar = null;
      this.modalDeletar = false;
    }
  }
  temTaxaRetorno(){
    return this.empresa && this.empresa.formasDeEntrega.find((item) => item.taxaExtraRetorno) != null
  }
}
