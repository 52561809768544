import {AfterViewInit, Component, HostListener, Injectable, OnInit, ViewChild} from '@angular/core';
import {Location} from "@angular/common";
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  CanDeactivate,
  Router,
  RouterStateSnapshot,
  UrlTree
} from "@angular/router";
import * as locale from "../../locale/calendar-ptbr";
import {BuscarContatoComponent} from "../../componentes/buscar-contato/buscar-contato.component";
import {ListaDePedidosComponent} from "../lista-de-pedidos/lista-de-pedidos.component";
import {PedidoLocalService} from "../../services/pedido-local.service";
import {ConstantsService} from "../../fidelidade/ConstantsService";
import {NgForm} from "@angular/forms";
import {PedidosService} from "../../services/pedidos.service";
import {PedidoLoja} from "../objetos/PedidoLoja";
import {BrindeService} from "../../services/brinde.service";
import {TelaAdicionarBrindeComponent} from "../tela-adicionar-brinde/tela-adicionar-brinde.component";
import {TelaResgateCashbackComponent} from "../tela-resgate-cashback/tela-resgate-cashback.component";
import {AutorizacaoService} from "../../services/autorizacao.service";
import {ContatosService} from "../../services/contatos.service";
import {TelaAdicionarProdutoComponent} from "../tela-adicionar-produto/tela-adicionar-produto.component";
import {TelaMesaEscolhidaComponent} from "../tela-mesa-escolhida/tela-mesa-escolhida.component";
import {TelaExpandidaComponent} from "../TelaExpandida";
import {DTOEmpresa} from "../objetos/DTOEmpresa";
import {DataHoraUtils} from "../../lib/DataHoraUtils";

@Component({
  selector: 'app-tela-novo-pedido',
  templateUrl: './tela-novo-pedido.component.html',
  styleUrls: ['./tela-novo-pedido.component.scss']
})
export class TelaNovoPedidoComponent extends TelaExpandidaComponent implements OnInit, AfterViewInit {
  @ViewChild('frm') frm: NgForm;
  @ViewChild('telaMesaEscolhida') telaMesaEscolhida: TelaMesaEscolhidaComponent;
  @ViewChild('buscarContato', {static: false}) buscarContato: BuscarContatoComponent;
  @ViewChild('listaDePedidos') listaDePedidos: ListaDePedidosComponent;
  @ViewChild('telaAdicionarBrinde') telaAdicionarBrinde: TelaAdicionarBrindeComponent;
  @ViewChild('telaResgateCashback') telaResgateCashback: TelaResgateCashbackComponent;
  @ViewChild('telaAdicionarProduto', { static: false }) telaAdicionarProduto: TelaAdicionarProdutoComponent;

  contato: any = {};
  informarCpf: any;
  informarDataNascimento: boolean;
  public buttonCount = 5;
  public info = true;
  public type: 'numeric' | 'input' = 'numeric';
  public pageSizes = false;
  public previousNext = true;
  public editandoProduto = false;
  public adicionandoProduto = false;
  public adicionandoBrinde = false;
  public nomeContato = '';
  public telefone = '';
  ptBr: any;

  public pedido: PedidoLoja = new PedidoLoja()
  private urlRetorno: any;
  msgErro: string;
  estaNoIframe: boolean;
  tituloPagina: string;
  codigoPedido: any;
  modoEdicao: boolean;
  bloqueado: any;
  empresa: any;
  devePreencherContato = true;
  pedidoDeMesa = false;
  desktop = false;
  telefoneNaoIdentificado = '+5500000000000';
  BALCAO = 'balcao';
  constructor(private router: Router, private activatedRoute: ActivatedRoute, private _location: Location,
              private brindeService: BrindeService, private autorizacaoService: AutorizacaoService,
              public contatosService: ContatosService,
              private pedidoLocalService: PedidoLocalService, private constantsService: ConstantsService,
              private pedidosService: PedidosService) {
    super();
    let state = window.history.state;

    if( state.contato )
      this.contato = state.contato;
    this.pedido.contato = this.contato;
    this.constantsService.campoCpf$.subscribe( data => this.informarCpf = data);
    this.constantsService.campoDataNascimento$.subscribe( campoDataNascimento => {
      this.informarDataNascimento = campoDataNascimento
    });

    this.desktop = window.innerWidth >= 1024;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.desktop = window.innerWidth >= 1024;
  }

  private setContato() {
    if(this.pedido.balcao) {
      this.pedidoLocalService.salveUrlAtual(this.BALCAO, this.router.url, window.history.state);
      return;
    }

    if (this.nomeContato && this.telefone) {
      const { codigoPais, telefone } = this.extraiCodigoPaisETelefone(this.telefone);
      this.contato.codigoPais = codigoPais;
      this.contato.telefone = telefone;
      this.contato.nome = this.nomeContato;
    }

    this.pedido.limpeTrocas();
    if(this.contato.dataNascimento)
      this.contato.dataNascimento = DataHoraUtils.obtenhaDataDaString(this.contato.dataNascimento);

    if(this.contato.cartoes && this.contato.cartoes.length){
      if(this.pedido.integracao ) {
        this.pedido.integracao.cartao =
          this.contato.cartoes.find(cartao => cartao.plano.id ===     this.empresa.integracaoPedidoFidelidade.plano.id);

      }
      this.pedido.setTrocas();
      this.carregueBrindes();
    }

    this.pedidoLocalService.salveUrlAtual(this.telefone, this.router.url, window.history.state);
  }

  carregueBrindes(){
    for(let i = 0 ; i <  this.pedido.trocas.length; i++){
      let troca = this.pedido.trocas[i];
      this.brindeService.listeDoPlano(troca.cartao.plano).then( (brindes) => {
        troca.brindes = brindes || [];
      })
    }
  }

  ngOnInit() {
    this.ptBr = locale.ptBR;

    this.constantsService.empresa$.subscribe( (empresa) => {
      if( !empresa ) return;

      this.empresa = empresa;
      this.pedido.dtoEmpresa = new DTOEmpresa(empresa);

      setTimeout(() => {
        if(this.pedido.dtoEmpresa && this.pedido.dtoEmpresa.ecommerce)
          this.telaAdicionarProduto.buscarNoServer = true;
      }, 10)

    });

    this.autorizacaoService.estaLogado().then( (resposta: any) => {
      this.bloqueado = resposta.bloqueado;
      if (!resposta.logado)
        this.redirecioneTelaLogin();

    });
  }

  contatoFoiBuscado({contato: contato, codigoPais: codigoPais, telefone: telefone}: any) {
    if ( contato && contato.id) {
      this.contato = contato
      this.nomeContato = contato.nome
      this.telefone = contato.codigoPais + contato.telefone

      if(this.pedido) this.pedido.contato = this.contato;

      this.setContato();
      this.pedidoLocalService.salvePedido(this.pedido);
    }
  }

  onSubmit() {

  }

  voltar() {
    if( this.adicionandoProduto  ) {
      this.adicionandoProduto = false;
      return;
    }

    if( this.editandoProduto  ) {
      this.editandoProduto = false;
      return;
    }

    if( this.adicionandoBrinde ) {
      this.adicionandoBrinde = false;
      return;
    }

    if((this.pedido && this.pedido.itens.length === 0) || this.pedido.id)
      this.pedidoLocalService.removaPedido(this.telefone);
    else
      this.pedidoLocalService.removaUrlSalva(this.telefone);

    if(this.urlRetorno)
      this.router.navigateByUrl(this.urlRetorno).then( () => {})
    else
      this._location.back();
  }



  exibaTelaAdicionarBrinde(resgate: any) {
    this.adicionandoBrinde = true;
    this.telaAdicionarBrinde.exibaResgate(resgate)
  }

  canceleAdicionarBrinde() {
    this.adicionandoBrinde = false;
  }

  public obtenhaTelefone() {
    if( this.telefone ) {
      return this.telefone;
    }

    return this.buscarContato.obtenhaTelefone();
  }

  salvePedido() {
    if( this.buscarContato && this.buscarContato.obtenhaTelefone()) {
      this.pedido.contato.telefone = this.buscarContato.obtenhaTelefone();
    }

    this.pedidoLocalService.salvePedido(this.pedido);
  }

  ngAfterViewInit(): void {
    this.constantsService.empresa$.subscribe( (empresa) => {
      if(empresa && empresa.id){
        this.empresa = empresa;
        this.pedido.integracao  = this.empresa.integracaoPedidoFidelidade;

        this.activatedRoute.queryParams.subscribe(queryParams => {
          this.urlRetorno = queryParams.urlRetorno;
          this.nomeContato = queryParams.nome;
          this.telefone = queryParams.tel;

          if(queryParams.bal != null){
            this.devePreencherContato = false;
            this.pedido.balcao =  true;
          }

          const idMesa = queryParams.idm;

          this.setContato();

          this.activatedRoute.params.subscribe(params => {

            this.codigoPedido = params.codigo;

            this.tituloPagina = !this.codigoPedido ? 'Novo Pedido' : 'Editar Pedido';
            this.modoEdicao = true;

            if(this.codigoPedido) {
              //this.modoEdicao = true;

              this.pedidosService.obtenhaPorCodigo(this.codigoPedido).then(async (pedido) => {
                this.tituloPagina +=  ' #' + pedido.codigo;

                let contato: any;

                if(!pedido.balcao){
                  contato = await     this.contatosService.obtenhaContato(
                    pedido.cliente.telefone ? pedido.cliente.codigoPais + pedido.cliente.telefone : null);
                } else {
                  this.devePreencherContato = false;
                }

                this.pedido = this.pedidoLocalService.obtenhaPedidoLojaPeloPedidoServer(pedido);

                if( contato ) {
                  this.pedido.contato = this.contato = contato;
                  this.nomeContato = contato.nome;
                  this.telefone = contato.codigoPais + contato.telefone;

                  this.setContato();
                }

                if( !contato && pedido.cliente.naoIdentificado) {
                  this.pedido.contato = this.contato = pedido.cliente
                  this.setContato();
                }

                if( pedido.mesa ) {
                  this.pedidoDeMesa = true;
                  this.devePreencherContato = (!this.empresa.pedidoMesaNaoIdentificado
                    || (this.telefone !== undefined && this.telefone !== '+5500000000000'));
                  this.telaMesaEscolhida.carregueMesa(pedido.mesa);
                }

                this.pedido.integracao = this.empresa.integracaoPedidoFidelidade;
                if(contato)
                  this.buscarContato.exibaContato(this.contato);
                this.listaDePedidos.exibaPedido(this.pedido);
                this.salvePedido();

              })
            }

            if( this.buscarContato ) this.buscarContato.exibaContato(this.contato);

            if( idMesa ) {
              this.pedidoDeMesa = true;
              this.devePreencherContato = (!this.empresa.pedidoMesaNaoIdentificado
                || (this.telefone !== undefined && this.telefone !== this.telefoneNaoIdentificado));

              if( !this.devePreencherContato ) {
                this.pedido.contato = this.contato = this.gereConsumidorNaoIdentificado();
                this.carreguePedidoDoLocalStorage();
                this.salvePedido();


              }
              this.listaDePedidos.exibaPedido(this.pedido);
              return;
            }

            this.setContato();

            this.carreguePedidoDoLocalStorage();
            this.listaDePedidos.exibaPedido(this.pedido);
          });
        });
      }
    });
  }

  private gereConsumidorNaoIdentificado() {
    return {
      telefone: '00000000000',
      codigoPais: '+55',
      nome: 'Consumidor não identificado',
      naoIdentificado: true
    };
  }

  valideTela() {
    delete  this.msgErro;

    if( this.devePreencherContato ) {
      if (!this.contato.telefone) {
        this.msgErro = 'Informe o telefone do contato'
        return false;
      }

      if (this.contato.telefone.length < 10) {
        this.msgErro = 'Informe um telefone completo com DDD'
        return false;
      }
    }

    if(!this.pedido || !this.pedido.itens || this.pedido.itens.length === 0) {
      this.msgErro = 'Selecione ao menos um produto antes de informar o Endereço de Entrega'
      return false;
    }

    if(this.frm.invalid){
      this.msgErro = 'Informe todos os campos antes de proseguir'
      return false;
    }

    return true;
  }

  configureEnderecoEntrega() {
    let queryParams: any = {}

    if(!this.pedido.balcao){
      this.contato.telefone = this.buscarContato.obtenhaTelefone();
      this.contato.codigoPais = this.buscarContato.obtenhaCodigoPais();

      queryParams = {
        tel: this.contato.codigoPais + this.contato.telefone,
        nome: this.contato.nome,
        idm: this.telaMesaEscolhida.obtenhaIdMesa()
      }
    }

    if( !this.valideTela() )  return;

    this.salvePedido();

    if(this.telaMesaEscolhida.modoGarcomAtivo())
      queryParams.mg = true


    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: queryParams,
      replaceUrl: true,
      queryParamsHandling: 'merge',
    }).then( () => {
      let querParams: any = {
        idm: this.telaMesaEscolhida.obtenhaIdMesa(),
        urlRetorno: this.urlRetorno,
        modoEdicao: this.modoEdicao
      }

      if(this.telaMesaEscolhida.modoGarcomAtivo())
        queryParams.mg = true

      if(!this.pedido.balcao){
        querParams.telefone = this.contato.codigoPais + this.contato.telefone
        this.router.navigate(['/admin/pedidos/novo/endereco'],
          {queryParams: querParams}).then( () => {});
      } else {
        querParams.telefone =  this.BALCAO;
        this.router.navigate(['/admin/pedidos/novo/pagamento'],
          {queryParams: querParams}).then( () => {});
      }
    });
  }

  private carreguePedidoDoLocalStorage() {
    let objPedido;
    if( this.contato.telefone ) {
      objPedido = this.pedidoLocalService.obtenhaPedido(this.contato.codigoPais + this.contato.telefone);
    } else {
       if(this.pedido.balcao)
         objPedido = this.pedidoLocalService.obtenhaPedido(this.BALCAO);
    }

    if( objPedido )
      this.pedido = objPedido;
  }

  salveBrinde(brindeResgatado: any) {
    if(brindeResgatado.brinde) {
      let itemPedido = {
        qtde: brindeResgatado.qtde,
        valorUnitario: 0,
        produto: { nome: brindeResgatado.brinde.nome, valorUnitario: 0},
        brinde: brindeResgatado.brinde ,
        cartao: { id: brindeResgatado.cartao.id },
        obtenhaDescricao: ( ) => {
          return brindeResgatado.brinde.nome;
        },
        obtenhaUnidade: () => {
           return 'x'
        }
      };
      this.pedido.itens.push(itemPedido)
    }
    this.salvePedido();
    this.listaDePedidos.refreshGrid();
    this.canceleAdicionarBrinde();
  }


  podeResgatarBrinde() {
    if(!this.pedido || !this.pedido.trocas) return false;

    return this.pedido.trocas.find( troca => troca.cartao.pontos > 0 && !troca.ehCashback);
  }

  private redirecioneTelaLogin() {
    this.router.navigateByUrl('/admin/login?t=' + window.location.pathname).then( () => {

    });
  }
  cliqueAdicionarProdutos() {
    this.adicionandoProduto = true;
    this.telaAdicionarProduto.novoItem();
  }

  editeItemPedido(dataItem: any) {
    this.telaAdicionarProduto.editeItem(dataItem.indice, dataItem.item)

    if(this.telaAdicionarProduto.produtoSelecionado){
      this.editandoProduto = true;
    } else {
      alert(String(`Produto não disponivel`))
    }


  }

  adicioneProduto(itemPedido: any) {
    this.listaDePedidos.adicione(itemPedido);
    this.salvePedido();
    this.adicionandoProduto = false;

  }

  atualizeProduto(dados: any){
    let indice = dados.indice,
      itemPedido: any = dados.item;

    this.listaDePedidos.atualize(indice , itemPedido );
    this.salvePedido();
    this.editandoProduto = false;
  }

  canceleAdicionar(event: any) {
    this.adicionandoProduto = false;
    this.editandoProduto = false;
  }

  verificarPedidoMesa() {
    if(this.devePreencherContato){
      this.contato.telefone = this.buscarContato.obtenhaTelefone();
      this.contato.codigoPais = this.buscarContato.obtenhaCodigoPais();
    }


    if( !this.valideTela() ) {
      return;
    }

    this.salvePedido();

    let queryParams: any = {
        tel: this.contato.codigoPais + this.contato.telefone,
        nome: this.contato.nome,
        idm: this.telaMesaEscolhida.obtenhaIdMesa()
      };

    if(this.telaMesaEscolhida.modoGarcomAtivo())
      queryParams.mg = true

    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: queryParams,
      replaceUrl: true,
      queryParamsHandling: 'merge',
    }).then( () => {
        queryParams = { telefone: this.contato.codigoPais + this.contato.telefone,
        idm: this.telaMesaEscolhida.obtenhaIdMesa(),
        urlRetorno: this.urlRetorno,
        modoEdicao: this.modoEdicao};

      if(this.telaMesaEscolhida.modoGarcomAtivo())
        queryParams.mg = true;

      if(this.telaMesaEscolhida.obtenhaIdGarcom())
        queryParams.idg = this.telaMesaEscolhida.obtenhaIdGarcom();

      if(this.telaMesaEscolhida.obtenhaCodigoComanda())
         queryParams.cc =  this.telaMesaEscolhida.obtenhaCodigoComanda()

      this.router.navigate(['/admin/pedidos/novo/confirmar'],
        {queryParams: queryParams}).then( () => {});
    });
  }

  identificarConsumidor() {
    this.devePreencherContato = true;
    this.pedido.contato = this.contato = {};
    this.salvePedido();
  }

  naoIdentificarConsumidor() {
    this.devePreencherContato = false;
    this.pedido.contato = this.contato = this.gereConsumidorNaoIdentificado()
    this.salvePedido();
  }

  private extraiCodigoPaisETelefone(telefoneCompleto: string) {
    return this.contatosService.extraiCodigoPaisETelefone(telefoneCompleto)
  }
}
