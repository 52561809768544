import { Component, OnInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { NotificacoesService } from '../../services/notificacoes.service';
import { ConstantsService } from '../ConstantsService';

@Component({
  selector: 'app-notificacoes',
  templateUrl: './notificacoes.component.html',
  styleUrls: ['./notificacoes.component.scss']
})
export class NotificacoesComponent implements OnInit {
  @ViewChild('formContent') formContent: ElementRef;
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.isMobile = window.innerWidth <= 767.98;
  }

  loading = false;
  notificacaoSelecionada: any = null;
  mostrandoTemplates = false;
  termoBusca = '';
  exibindoFormMobile = false;
  isMobile = window.innerWidth <= 767.98;

  // Arrays de notificações por tipo
  notificacoesFidelidade = [];
  notificacoesPedidos = [];
  notificacoesSDR = [];
  notificacoesEntregas = [];

  // Arrays originais para busca
  private _notificacoesFidelidadeOriginal = [];
  private _notificacoesPedidosOriginal = [];
  private _notificacoesSDROriginal = [];
  private _notificacoesEntregasOriginal = [];

  // Flags de módulos
  temModuloPedidos = false;
  temModuloFidelidade = false;

  constructor(
    private notificacoesService: NotificacoesService,
    private constantsService: ConstantsService
  ) { }

  ngOnInit() {
    this.loading = true;

    // Carrega flags de módulos
    this.constantsService.moduloPedido$.subscribe(data => this.temModuloPedidos = data);
    this.constantsService.moduloFidelidade$.subscribe(data => this.temModuloFidelidade = data);

    // Carrega notificações
    this.notificacoesService.todas().then((notificacoes) => {
      this.loading = false;

      // Separa notificações por tipo
      this.notificacoesEntregas = this.filtreRemovendo(notificacoes,
        notificacao => notificacao.tipoDeNotificacao.indexOf('Entregador') >= 0);
      this._notificacoesEntregasOriginal = [...this.notificacoesEntregas];

      this.filtreRemovendo(notificacoes,
        notificacao => notificacao.tipoDeNotificacao === 'Carrinho Abandonado');

      this.notificacoesPedidos = this.filtreRemovendo(notificacoes,
        notificacao => notificacao.tipoDeNotificacao.indexOf('Pedido') >= 0 ||
          notificacao.tipoDeNotificacao.indexOf('Comanda') >= 0);
      this._notificacoesPedidosOriginal = [...this.notificacoesPedidos];

      this.notificacoesSDR = this.filtreRemovendo(notificacoes,
        notificacao => notificacao.tipoDeNotificacao.indexOf('Etapa ') >= 0);
      this._notificacoesSDROriginal = [...this.notificacoesSDR];

      this.notificacoesFidelidade = notificacoes;
      this._notificacoesFidelidadeOriginal = [...this.notificacoesFidelidade];

      // Seleciona primeira notificação por padrão
      if (this.notificacoesFidelidade.length > 0) {
        this.notificacaoSelecionada = this.notificacoesFidelidade[0];
      }

      // Observa mudanças na busca
      this.observarBusca();
    });
  }

  private observarBusca() {
    // Implementar debounce se necessário
    setInterval(() => {
      if (this.termoBusca) {
        const termo = this.termoBusca.toLowerCase();

        this.notificacoesFidelidade = this._notificacoesFidelidadeOriginal.filter(
          n => n.tipoDeNotificacao.toLowerCase().includes(termo)
        );

        this.notificacoesPedidos = this._notificacoesPedidosOriginal.filter(
          n => n.tipoDeNotificacao.toLowerCase().includes(termo)
        );

        this.notificacoesSDR = this._notificacoesSDROriginal.filter(
          n => n.tipoDeNotificacao.toLowerCase().includes(termo)
        );

        this.notificacoesEntregas = this._notificacoesEntregasOriginal.filter(
          n => n.tipoDeNotificacao.toLowerCase().includes(termo)
        );
      } else {
        // Restaura arrays originais
        this.notificacoesFidelidade = [...this._notificacoesFidelidadeOriginal];
        this.notificacoesPedidos = [...this._notificacoesPedidosOriginal];
        this.notificacoesSDR = [...this._notificacoesSDROriginal];
        this.notificacoesEntregas = [...this._notificacoesEntregasOriginal];
      }
    }, 300);
  }

  selecionarNotificacao(notificacao: any) {
    this.notificacaoSelecionada = notificacao;
    this.mostrandoTemplates = false;
    this.exibindoFormMobile = true;

    // Scroll para o topo do form após um pequeno delay para garantir que o DOM foi atualizado
    setTimeout(() => {
      if (this.formContent) {
        const headerOffset = 100; // Altura aproximada do header + margem
        const elementPosition = this.formContent.nativeElement.getBoundingClientRect().top;
        const offsetPosition = elementPosition - headerOffset;

        window.scrollTo({
          top: window.pageYOffset + offsetPosition,
          behavior: 'smooth'
        });
      }
    }, 100);
  }

  voltarParaLista() {
    this.exibindoFormMobile = false;
  }

  mostrarTemplates() {
    this.mostrandoTemplates = true;
    this.notificacaoSelecionada = null;
    this.exibindoFormMobile = true;

    // Força o scroll para o topo imediatamente
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }

  getIconeNotificacao(tipo: string): string {
    const icones = {
      // Fidelidade
      'Aniversário': 'fas fa-birthday-cake',
      'Bem-Vindo': 'fas fa-handshake',
      'Cardápio': 'fas fa-utensils',
      'Cartão Completo': 'fas fa-award',
      'Cliente Perdido': 'fas fa-user-slash',
      'Confirmar Cartão': 'fas fa-check-circle',
      'Ganhou Pontos': 'fas fa-coins',
      'Link Extrato': 'fas fa-file-invoice',
      'Novo Cartão': 'fas fa-id-card',
      'Resgatou Cartão': 'fas fa-gift',
      'Resgatou Prêmio': 'fas fa-trophy',
      'Validar Cartão': 'fas fa-user-check',

      // Pedidos
      'Avaliar Pedido': 'fas fa-star',
      'Comanda Fechada': 'fas fa-cash-register',
      'ConfirmacaoPedido': 'fas fa-clipboard-check',
      'Mensagem Saudação Whatsapp Pedido': 'fas fa-comment-dots',
      'Pedido Alterado': 'fas fa-edit',
      'Pedido Cancelado': 'fas fa-times-circle',
      'Pedido Confirmado': 'fas fa-check-circle',
      'Pedido Em Preparação': 'fas fa-fire-alt',
      'Pedido Entregue': 'fas fa-check-double',
      'Pedido Pronto': 'fas fa-concierge-bell',
      'Pedido Realizado Cardapio Online': 'fas fa-globe',
      'Pedido Saiu Para Entrega': 'fas fa-motorcycle',

      // SDR
      'Etapa Inicial': 'fas fa-flag',
      'Etapa Intermediária': 'fas fa-clock',
      'Etapa Final': 'fas fa-flag-checkered',
      'Etapa Cancelada': 'fas fa-ban',

      // Entregas
      'Entregador Próximo': 'fas fa-running',
      'Entregador Chegou': 'fas fa-store',
      'Entregador a Caminho': 'fas fa-route',
      'Entrega Concluída': 'fas fa-box-check'
    };

    // Procura por correspondência parcial se não encontrar exata
    const tipoNormalizado = tipo.toLowerCase();
    for (const [key, value] of Object.entries(icones)) {
      if (tipoNormalizado.includes(key.toLowerCase())) {
        return value;
      }
    }

    // Ícone padrão se não encontrar correspondência
    return 'far fa-bell';
  }

  private filtreRemovendo(lista: any[], funcaoFiltro: (item: any) => boolean): any[] {
    let listaFiltrada = lista.filter(funcaoFiltro);

    listaFiltrada.forEach(function(element) {
      let index = lista.indexOf(element);
      lista.splice(index, 1);
    });

    return listaFiltrada;
  }
}
