import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {EmpresasService} from "../../superadmin/services/empresas.service";
import {PlanosService} from "../services/planos.service";

@Component({
  selector: 'app-form-integracaofidelidade',
  templateUrl: './form-integracaofidelidade.component.html',
  styleUrls: ['./form-integracaofidelidade.component.scss']
})
export class FormIntegracaofidelidadeComponent implements OnInit {
  @Input() empresa: any = {};
  @Input() usuario: any = {};
  @ViewChild('frm', {}) frm:  any;
  @ViewChild('frmFidelidade', {}) frmFidelidade:  any;
  atividadeManual: any ;
  planos: []
  planoIntegrado: any;
  mensagemSucesso: any;
  mensagemErro: any;
  ativando: any;
  alterando: any;
  alterandoManual: any;
  desativando: boolean;
  confirmarDesativar: boolean;
  novoCashback: number;
  novoValorAtividade: number
  novoCashbackManual: number;
  novoValorAtividadeManual: number
  pontuarSoLoja: boolean
  constructor(private empresaService: EmpresasService, private planosService: PlanosService) { }

  ngOnInit(): void {
    this.carreguePlanos();

    if(!this.empresa.integracaoFidelidade)
      this.empresa.integracaoFidelidade = {}
  }

  private carreguePlanos() {
      this.planosService.obtenhaPlanos(this.empresa.id, 0).then( (respEmpresas: any) => {
        this.planos = respEmpresas.data;

        if(this.empresa.integracaoPedidoFidelidade){
          this.setCashbackExibir(this.empresa.integracaoPedidoFidelidade.atividade)
          this.planos.forEach((plano: any) => {
            if(plano.id === this.empresa.integracaoPedidoFidelidade.plano.id){
              plano.atividades.forEach((atividade: any) => {
                if(!atividade.integrada){
                  this.atividadeManual = atividade;
                  this.setCashbackExibir(atividade)
                }
              })
            }
          })
        }
      });
  }

  setCashbackExibir(atividade){
    if(atividade.cashback)
       atividade.cashbackFormatado = String(`${this.obtenhaValorPercentual(atividade.cashback)}%`);

  }

  obtenhaValorPercentual(cashback: any){
    if(cashback > 0 && cashback <= 1)
      return (cashback * 100).toFixed(1)

    return cashback;
  }

  onSubmit() {
    delete     this.mensagemErro;

    if(this.frm.valid){
      this.ativando = true;
      let dados = Object.assign({}, this.planoIntegrado);
      dados.cashback =      dados.cashback / 100;

      this.empresaService.ativeIntegracao( this.empresa, dados).then((resp) => {
        this.ativando = false;
        this.empresa.integracaoPedidoFidelidade = resp;
        this.setCashbackExibir(this.empresa.integracaoPedidoFidelidade.atividade)
      }).catch( erro => {
        this.ativando = false;
        this.mensagemErro   = erro;
      })
    }
  }

  onSubmitFidelidade(){
    delete     this.mensagemErro;
    if(this.frmFidelidade.valid){
      this.ativando = true;
      this.empresaService.ativeFidelidadeGcom( this.empresa, this.empresa.integracaoFidelidade.idLoja).then((resp) => {
        this.ativando = false;
        this.empresa.integracaoFidelidade = resp;
      }).catch( erro => {
        this.ativando = false;
        this.mensagemErro   = erro;
      })
    }
  }

  desativarIntegracaoFidelidade(){
    if(  this.desativando) return;

    this.desativando = true;

    this.empresaService.desativeIntegracao( this.empresa ).then((resp) => {
      this.desativando = false;
      delete this.empresa.integracaoPedidoFidelidade;
      delete this.atividadeManual;
    }).catch( erro => {
      this.desativando = false;
      alert(erro)
    })
  }

  desativarIntegracaoFidelidadeGcom(){
    if(  this.desativando) return;

    this.desativando = true;
    this.empresaService.desativeIntegracaoFidelidade( this.empresa ).then((resp) => {
      this.desativando = false;
      this.empresa.integracaoFidelidade = {}
    }).catch( erro => {
      this.desativando = false;
      alert(erro)
    })
  }

  confirmeDesativar() {
    this.confirmarDesativar = true;
  }

  canceleDesativar() {
    this.confirmarDesativar = false;
  }

  atualizeValorCashback(){
    if(this.alterando) return;
    let atividade =  this.empresa.integracaoPedidoFidelidade.atividade;

    if(atividade.cashback !== this.novoCashback){
      this.alterando = true;

      this.empresaService.atualizeValorAtividadeCashback( this.empresa,  this.empresa.integracaoPedidoFidelidade.atividade,
        this.novoCashback).then((resp) => {
        atividade.cashback =  Number((this.novoCashback / 100 ).toFixed(4));
        this.setCashbackExibir( atividade);
        this.alterando = false;
        this.canceleEditarCashback();
      }).catch( erro => {
        this.alterando = false;
        alert(erro)
      })
    } else {
      this.canceleEditarCashback();
    }
  }

  atualizeValorAtividadeIntegrada(){
    if(this.alterando) return;

    let atividade =  this.empresa.integracaoPedidoFidelidade.atividade;
    if( atividade.pontosGanhos !==  this.novoValorAtividade){
      this.alterando = true;

      this.empresaService.atualizeValorAtivdade( this.empresa,  atividade, this.novoValorAtividade).then((resp) => {
        atividade.pontosGanhos =  this.novoValorAtividade;
        this.alterando = false;
        this.canceleEditarCashback();
      }).catch( erro => {
        this.alterando = false;
        alert(erro)
      })
    } else {
      this.canceleEditarCashback();
    }
  }

  atualizeValorCashbackManual(){
    if(this.alterandoManual) return;

    if( this.novoCashbackManual !== this.atividadeManual.cashback){
      this.alterandoManual = true;
      this.empresaService.atualizeValorAtividadeCashback( this.empresa, this.atividadeManual,
        this.novoCashbackManual).then((resp) => {
        this.atividadeManual.cashback = Number(this.novoCashbackManual / 100).toFixed(2);
        this.setCashbackExibir( this.atividadeManual);
        this.alterandoManual = false;
        this.canceleEditarManual();
      }).catch( erro => {
        this.alterandoManual = false;
        alert(erro)
      })
    } else {
      this.canceleEditarManual();
    }
  }

  atualizeValorAtividadeManual(){
    if(this.alterandoManual) return;

    if(this.atividadeManual.pontosGanhos !== this.novoValorAtividadeManual){
      this.alterandoManual = true;
      this.empresaService.atualizeValorAtivdade( this.empresa, this.atividadeManual,
        this.novoValorAtividadeManual).then((resp) => {
        this.atividadeManual.pontosGanhos = this.novoValorAtividadeManual;
        this.alterandoManual = false;
        this.canceleEditarManual();
      }).catch( erro => {
        this.alterandoManual = false;
        alert(erro)
      })
    } else {
      this.canceleEditarManual();
    }
  }

  //
  alterouPontuarLoja(valor: any) {
    this.empresaService.atualizePontuarSoLoja(this.empresa, valor).then((resp) => {

    }).catch( erro => {
      this.empresa.integracaoPedidoFidelidade.pontuarSoLoja =  !valor;
      alert(erro)
    })
  }

  alterouResgatarBrindes(valor: any){
    this.empresaService.atualizeResgatarBrindes(this.empresa, valor).then((resp) => {

    }).catch( erro => {
      this.empresa.integracaoPedidoFidelidade.pontuarMesas =  !valor;
      alert(erro)
    })
  }
  //
  alterouPontuarMesas(valor: any) {
    this.empresaService.atualizePontuarMesas(this.empresa, valor).then((resp) => {

    }).catch( erro => {
      this.empresa.integracaoPedidoFidelidade.pontuarMesas =  !valor;
      alert(erro)
    })
  }

  editarCasback() {
    this.novoCashback = this.obtenhaValorPercentual(this.empresa.integracaoPedidoFidelidade.atividade.cashback)
    this.empresa.integracaoPedidoFidelidade.editar = true;
  }

  editarValorAtividade(){
    this.novoValorAtividade = this.empresa.integracaoPedidoFidelidade.atividade.pontosGanhos;
    this.empresa.integracaoPedidoFidelidade.editar = true;
  }

  editarCasbackManual() {
    this.novoCashbackManual = this.obtenhaValorPercentual(this.atividadeManual.cashback);
    this.pontuarSoLoja = this.atividadeManual.pontuarSoLoja;
    this.atividadeManual.editar = true;
  }

  editarValorAtividadeManual(){
    this.novoValorAtividadeManual = this.atividadeManual.pontosGanhos;
    this.pontuarSoLoja = this.atividadeManual.pontuarSoLoja;
    this.atividadeManual.editar = true;
  }


  canceleEditarCashback(){
    this.empresa.integracaoPedidoFidelidade.editar = false;
  }

  canceleEditarManual(){
    this.atividadeManual.editar = false;
  }

  obtenhaFormatoPercentual(){
    return String(`0.0"%"`);
  }

  acumulaReais() {
    if(!this.empresa || ! this.empresa.integracaoPedidoFidelidade) return false;

    return this.empresa.integracaoPedidoFidelidade.plano.tipoDeAcumulo === 'Reais'
  }

  pontuacaoVariavelPorAtividade() {
    if(!this.empresa || ! this.empresa.integracaoPedidoFidelidade) return false;

    return this.empresa.integracaoPedidoFidelidade.plano.tipoDePontuacao.tipo === 'qtde-variavel-por-atividade'
  }

  planoIntegrarVariavelPorAtividade(){
    return this.planoIntegrado && this.planoIntegrado.tipoDePontuacao.tipo  === 'qtde-variavel-por-atividade';
  }

  planoIntegrarAcumulaReais() {
    return this.planoIntegrado && this.planoIntegrado.tipoDeAcumulo === 'Reais'
  }

  integrarComGcom(){
    if(this.empresa.integracaoPedidoFidelidade || !this.empresa.integracaoDelivery) return false;

    let sistemaParceiro = this.empresa.integracaoDelivery.sistema;

    return sistemaParceiro === 'ecletica' || sistemaParceiro === 'gcom'
  }

}
