import { TelaTaxasDeEntregasCalculadasComponent } from './../tela-areas-de-entrega/tela-taxas-de-entregas-calculadas/tela-taxas-de-entregas-calculadas.component';
import {
  RouterModule,
  Route,
  CanDeactivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import {Injectable, NgModule} from '@angular/core';
import {FidelidadeComponent} from "./fidelidade.component";
import {HomeComponent} from "../home/home.component";
import {NotificacoesComponent} from "./noticacoes/notificacoes.component";
import {RelatoriosComponent} from "../relatorios/relatorios.component";
import {ContatosComponent} from "../contatos/contatos.component";
import {ListaDeMensagensComponent} from "../lista-de-mensagens/lista-de-mensagens.component";
import {MarketingComponent} from "../marketing/marketing.component";
import {NovaCampanhaComponent} from "../nova-campanha/nova-campanha.component";
import {TelaEnviarMensagensComponent} from "../tela-enviar-mensagens/tela-enviar-mensagens.component";
import {VerContatoComponent} from "../ver-contato/ver-contato.component";
import {CampanhaDetalhesComponent} from "../campanha-detalhes/campanha-detalhes.component";
import {NovoCartaoComponent} from "../novo-cartao/novo-cartao.component";
import {AdicionarPontosComponent} from "./adicionar-pontos/adicionar-pontos.component";
import {TrocarPontosComponent} from "../trocar-pontos/trocar-pontos.component";
import {MeuPlanoComponent} from "../meuplano/meu-plano.component";
import {PagamentoCartaoComponent} from "./pagamento-cartao/pagamento-cartao.component";
import {PagamentoCartaoDescontoComponent} from "./pagamento-cartao-desconto/pagamento-cartao-desconto.component";
import {VerContatoIframeComponent} from "../ver-contato-iframe/ver-contato-iframe.component";
import {TelaNovoPedidoComponent} from "../pedidos/tela-novo-pedido/tela-novo-pedido.component";
import {TelaEnderecoEntregaComponent} from "../pedidos/tela-endereco-entrega/tela-endereco-entrega.component";
import {TelaEscolherPagamentoComponent} from "../pedidos/tela-escolher-pagamento/tela-escolher-pagamento.component";
import {TelaConfirmarPedidoComponent} from "../pedidos/tela-confirmar-pedido/tela-confirmar-pedido.component";
import {TelaCriarEnderecoComponent} from "../pedidos/tela-criar-endereco/tela-criar-endereco.component";
import {TelaMudarStatusComponent} from "../tela-mudar-status/tela-mudar-status.component";
import {TelaCadastroProdutosComponent} from "../tela-cadastro-produtos/tela-cadastro-produtos.component";
import {TelaPedidosComponent} from "../pedidos/tela-pedidos/tela-pedidos.component";
import {TelaConfigEmpresaComponent} from "./tela-config-empresa/tela-config-empresa.component";
import {WizardConfigEmpresaComponent} from "./wizard-config-empresa/wizard-config-empresa.component";
import {AssinaturaPagamentoComponent} from "./assinatura-pagamento/assinatura-pagamento.component";
import {PedidoDetalhesComponent} from "./pedido-detalhes/pedido-detalhes.component";
import {CadastroFormasDePagamentoComponent} from "../formas-de-pagamento/cadastro-formas-de-pagamento/cadastro-formas-de-pagamento.component";
import {WhatsappInvalidoComponent} from "../whatsapp-invalido/whatsapp-invalido.component";
import {CadastroMesaComponent} from "../mesa/cadastro-mesa/cadastro-mesa.component";
import {FinanceiroDeliveryComponent} from "./financeiro-delivery/financeiro-delivery.component";
import {FinanceiroMesasComponent} from "./financeiro-mesas/financeiro-mesas.component";
import {TestadorchatBotComponent} from "../chatbot/testadorchat-bot/testadorchat-bot.component";
import {ConversasChatbotComponent} from "../chatbot/conversas-chatbot/conversas-chatbot.component";
import {ConfigMensagensComponent} from "../chatbot/config-mensagens/config-mensagens.component";
import {Observable} from "rxjs";
import {TelaFecharComandaComponent} from "../pedidos/tela-fechar-comanda/tela-fechar-comanda.component";
import {TelaPedidosMesasComponent} from "../pedidos/mesa/tela-pedidos-mesas/tela-pedidos-mesas.component";
import {TelaCadastroBannerComponent} from "../banner/tela-cadastro-banner/tela-cadastro-banner.component";
import {NovoBannerComponent} from "../banner/novo-banner/novo-banner.component";
import {TelaNovoPedidoMesaComponent} from "../pedidos/mesa/tela-novo-pedido-mesa/tela-novo-pedido-mesa.component";
import {TelaTrocarMesaComponent} from "../pedidos/tela-trocar-mesa/tela-trocar-mesa.component";
import {TelaFinalizarPedidoMesaComponent} from "../tela-finalizar-pedido-mesa/tela-finalizar-pedido-mesa.component";
import {ListaDeNotificacoesAppComponent} from "../marketing/app/lista-notificacoes-app/lista-de-notificacoes-app.component";
import {NovaCampanhaAppComponent} from "../marketing/app/nova-campanha-app/nova-campanha-app.component";
import {AssinaturaTrocarcartaoComponent} from "./assinatura-trocarcartao/assinatura-trocarcartao.component";
import {TelaAreasDeEntregaComponent} from "../tela-areas-de-entrega/tela-areas-de-entrega.component";
import {ConfigInstagramComponent} from "../instagram-bot/config-instagram/config-instagram.component";
import {VitrinesComponent} from "./vitrines/vitrines.component";
import {TelaConfiguracaoTemasComponent} from "./temas/tela-configuracao-temas/tela-configuracao-temas.component";

import {
  AuthContatosGuard, AuthEstoqueGuard,
  AuthLojaGuard,
  AuthNoticacoesGuard,
  AuthPedidosGuard,
  AuthProdutosGuard
} from "../guards/auth-loja.guard";
import {ConfigWhatsappComponent} from "../cloud-whatsapp/config-whatsapp/config-whatsapp.component";
import {TelaListaAvaliacoesComponent} from "../avaliacoes/tela-lista-avaliacoes/tela-lista-avaliacoes.component";
import {ModuloEntregadoresComponent} from "./modulo-entregadores/modulo-entregadores.component";

import {TelaTemplatesPromptsComponent} from "../ia/tela-templates-prompts/tela-templates-prompts.component";
import {
  TelaConfigAtendenteVirtualComponent
} from "../chatbot/tela-config-atendente-virtual/tela-config-atendente-virtual.component";
import {ListagemQrcodeComponent} from "../qrcode/listagem-qrcode/listagem-qrcode.component";
import {CadQrcodeComponent} from "../qrcode/cad-qrcode/cad-qrcode.component";
import {ListaTemplatesPromptsDbComponent} from "../ia/lista-templates-prompts-db/lista-templates-prompts-db.component";
import {TelaTemplatesPromptsDbComponent} from "../ia/tela-templates-prompts-db/tela-templates-prompts-db.component";
import { EditorTemplateNotificacoesComponent } from './noticacoes/visual/editor-template-notificacoes/editor-template-notificacoes.component';
import { TelaChamadasIaComponent } from '../chatbot/tela-chamadas-ia/tela-chamadas-ia.component';
import { ListaIntencoesComponent } from '../ia/intencoes/lista-intencoes/lista-intencoes.component';
import {MeuPdvComponent} from "./meu-pdv/meu-pdv.component";
import {TelaCrmLeadsComponent} from "../tela-crm-leads/tela-crm-leads.component";
import {AssinaturaBlackfridayComponent} from "./assinatura-blackfriday/assinatura-blackfriday.component";
import {
  TelaPedidosErrosImportarComponent
} from "../pedidos/tela-pedidos-erros-importar/tela-pedidos-erros-importar.component";
import {AtivarHubPagarmeComponent} from "./ativar-hub-pagarme/ativar-hub-pagarme.component";
import {RelatorioPontuacaoComponent} from "./relatorio-pontuacao/relatorio-pontuacao.component";
import {AtivarPagbankConnectComponent} from "./ativar-pagbank-connect/ativar-pagbank-connect.component";
import { TelaCriarTemaPersonalizadoComponent } from '../temas/tela-criar-tema-personalizado/tela-criar-tema-personalizado.component';
import { DashboardMiaComponent } from '../chatbot/dashboard-mia/dashboard-mia.component';
import {UserResolver} from "../services/user.resolver";
import {TelaGerenciarEstoqueComponent} from "../tela-gerenciar-estoque/tela-gerenciar-estoque.component";
import { EditorFluxoComponent } from '../chatbot/typebot/editor-fluxo/editor-fluxo.component';
import { TelaConfigTypebotComponent } from '../chatbot/tela-config-typebot/tela-config-typebot.component';
import { ClassificacaoContatoComponent } from '../components/classificacao-contato/classificacao-contato.component';
import {KanbanContatosComponent} from "./kanban-contatos/kanban-contatos.component";



@Injectable()
export class CanDeactivateNovoPedido implements CanDeactivate<TelaNovoPedidoComponent> {
  constructor() {}

  canDeactivate(
    component: TelaNovoPedidoComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState: RouterStateSnapshot
  ): Observable<boolean|UrlTree>|Promise<boolean|UrlTree>|boolean|UrlTree {
    return true;
    /*
    if( nextState.url.indexOf('pedidos/novo') !== - 1 ||
      nextState.url.indexOf('pedidos/endereco/editar') !== -1 ||
      nextState.url.indexOf('pedidos/') !== -1 ) {
      return true;
    }

    if( component.pedido.itens.length > 0 ) {
      return window.confirm("Você tem alterações não salvas. Se sair elas serão perdidas.");
    }

    return true;
     */
  }
}


const routes: Route[] = [
  {
    path: '', component: FidelidadeComponent,
    resolve: { user: UserResolver },
    children: [
      {path: 'index', pathMatch: 'full', component: HomeComponent},
      {path: 'wizard', component: WizardConfigEmpresaComponent},
      {path: 'whatsapp/invalido', component: WhatsappInvalidoComponent},
      {path: 'meupdv', component: MeuPdvComponent},
      {path: 'pedidos', component: TelaPedidosComponent},
      {path: 'pedidos/erros', component: TelaPedidosComponent},
      {path: 'pedidos/errosimportar', component: TelaPedidosErrosImportarComponent},
      {path: 'pedidos/onlineaguardando', component: TelaPedidosComponent},
      {path: 'pedidos/contestados', component: TelaPedidosComponent},
      {path: 'pedidos/alteracaoendereco', component: TelaPedidosComponent},
      {path: 'pedidos-mesas', component: TelaPedidosMesasComponent},
      {path: 'pedidos-mesas/erros', component: TelaPedidosMesasComponent},
      {path: 'pedidos/novo-mesa', component: TelaNovoPedidoMesaComponent},
      {path: 'pedidos/trocar-mesa', component: TelaTrocarMesaComponent},
      {path: 'pedidos/novo', component: TelaNovoPedidoComponent, canDeactivate: [CanDeactivateNovoPedido]},
      {path: 'pedidos/:codigo', canActivate: [AuthPedidosGuard], component: TelaNovoPedidoComponent},
      {path: 'pedidos/novo/endereco', component: TelaEnderecoEntregaComponent, canDeactivate: [CanDeactivateNovoPedido]},
      {path: 'pedidos/novo/endereco/novo', component: TelaCriarEnderecoComponent, canDeactivate: [CanDeactivateNovoPedido]},
      {path: 'pedidos/endereco/editar', component: TelaCriarEnderecoComponent},
      {path: 'pedidos/novo/confirmar', component: TelaConfirmarPedidoComponent, canDeactivate: [CanDeactivateNovoPedido]},
      {path: 'pedidos/novo/pagamento', component: TelaEscolherPagamentoComponent, canDeactivate: [CanDeactivateNovoPedido]},
      {path: 'pedidos/mudar-status/:codigo', component: TelaMudarStatusComponent},
      {path: 'pedidos/finalizar-mesa/:codigo', component: TelaFinalizarPedidoMesaComponent},
      {path: 'pedidos/detalhes/:guid', component: PedidoDetalhesComponent},
      {path: 'contatos/classificar', component: ClassificacaoContatoComponent},
      {path: 'contatos/novo', component: NovoCartaoComponent},
      {path: 'contatos/pontos/adicionar', component: AdicionarPontosComponent},
      {path: 'contatos/pontos/trocar', component: TrocarPontosComponent},
      {path: 'contatos',    canActivate: [AuthContatosGuard], component: ContatosComponent},
      {path: 'contatos-kanban',    canActivate: [AuthContatosGuard], component: KanbanContatosComponent},
      {path: 'chatbot/testar', component: TestadorchatBotComponent},
      {path: 'chatbot/configuracoes', component: TelaConfigAtendenteVirtualComponent},
      {path: 'chatbot/conversas', component: ConversasChatbotComponent},
      {path: 'chatbot/chamadas-ia', component: TelaChamadasIaComponent},
      {path: 'chatbot/configuracoes/mensagens', component: ConfigMensagensComponent},
      {path: 'contatos/:zap', component: VerContatoComponent},
      {path: 'contatos/cpf/:cpf', component: VerContatoComponent},
      {path: 'contatos/id/:id', component: VerContatoComponent},
      {path: 'contatos/iframe/:zap', component: VerContatoIframeComponent},
      {path: 'formas-pagamento', component: CadastroFormasDePagamentoComponent},
      {path: 'mesas', component: CadastroMesaComponent},
      {path: 'notificacoes', canActivate: [AuthNoticacoesGuard], component: NotificacoesComponent},
      {path: 'relatorios', component: RelatoriosComponent},
      {path: 'mensagens', canActivate: [AuthNoticacoesGuard], component: ListaDeMensagensComponent},
      {path: 'plano', component: MeuPlanoComponent},
      {path: 'banner', component: TelaCadastroBannerComponent},
      {path: 'banner/novo', component: NovoBannerComponent},
      {path: 'banner/:id', component: NovoBannerComponent},
      {path: 'pagamento/cartao', component: PagamentoCartaoComponent},
      {path: 'pagamento/cartao/desconto', component: PagamentoCartaoDescontoComponent},
      {path: 'marketing', component: MarketingComponent},
      {path: 'crm', component: TelaCrmLeadsComponent},
      {path: 'marketing-app', component: ListaDeNotificacoesAppComponent},
      {path: 'nova-campanha-app', component: NovaCampanhaAppComponent},
      {path: 'nova-campanha-app/:idc', component: NovaCampanhaAppComponent},
      {path: 'campanha/:idc/detalhes', component: CampanhaDetalhesComponent},
      {path: 'instagram-bot', component: ConfigInstagramComponent},
      {path: 'whatsapp', component: ConfigWhatsappComponent},
      {path: 'nova-campanha', component: NovaCampanhaComponent},
      {path: 'nova-campanha/:idc', component: NovaCampanhaComponent},
      {path: 'tela-enviar-mensagens/:idc', component: TelaEnviarMensagensComponent},
      {path: 'estoque',   canActivate: [AuthEstoqueGuard], component: TelaGerenciarEstoqueComponent},
      {path: 'estoque/alertas',   canActivate: [AuthEstoqueGuard], component: TelaGerenciarEstoqueComponent},
      {path: 'produtos',   canActivate: [AuthProdutosGuard], component: TelaCadastroProdutosComponent},
      {path: 'vitrines',  canActivate: [AuthProdutosGuard],  component: VitrinesComponent},
      {path: 'loja', canActivate: [AuthLojaGuard], component: TelaConfigEmpresaComponent},
      {path: 'loja/formaspagamento/:formapagamento', canActivate: [AuthLojaGuard], component: TelaConfigEmpresaComponent},
      {path: 'loja/integracao/:parceiro', canActivate: [AuthLojaGuard], component: TelaConfigEmpresaComponent},
      {path: 'loja/retorno/pagarme', canActivate: [AuthLojaGuard], component: AtivarHubPagarmeComponent},
      {path: 'loja/retorno/pagseguro', canActivate: [AuthLojaGuard], component: AtivarPagbankConnectComponent},
      {path: 'financeiro-delivery', component: FinanceiroDeliveryComponent},
      {path: 'fidelidade/pontuacoes', component: RelatorioPontuacaoComponent},
      {path: 'financeiro-mesas', component: FinanceiroMesasComponent},
      {path: 'financeiro-balcao', component: FinanceiroDeliveryComponent},
      {path: 'areas-entrega', canActivate: [AuthLojaGuard], component: TelaAreasDeEntregaComponent},
      {path: 'taxas', canActivate: [AuthLojaGuard], component: TelaTaxasDeEntregasCalculadasComponent},
      {path: 'fechar-mesa/:id', component: TelaFecharComandaComponent},
      {path: 'temas',  canActivate: [AuthLojaGuard], component: TelaConfiguracaoTemasComponent},
      {path: 'temas/criar',  canActivate: [AuthLojaGuard], component: TelaCriarTemaPersonalizadoComponent},
      {path: 'temas/criar/:id',  canActivate: [AuthLojaGuard], component: TelaCriarTemaPersonalizadoComponent},
      {path: 'avaliacoes',  canActivate: [AuthLojaGuard], component: TelaListaAvaliacoesComponent},
      {path: 'chatbot/analytics',  canActivate: [AuthLojaGuard], component: DashboardMiaComponent},
      {path: 'chatbot/editor-fluxo',  canActivate: [AuthLojaGuard], component: EditorFluxoComponent},
      {path: 'chatbot/config-typebot',  canActivate: [AuthLojaGuard], component: TelaConfigTypebotComponent},
      {path: 'entregadores', canActivate: [AuthLojaGuard], component: ModuloEntregadoresComponent},
      {path: 'templates-prompts-db',  canActivate: [AuthLojaGuard], component: ListaTemplatesPromptsDbComponent},
      {path: 'templates-prompts-db/nova',  canActivate: [AuthLojaGuard], component: TelaTemplatesPromptsDbComponent},
      {path: 'templates-prompts-db/nova/:id',  canActivate: [AuthLojaGuard], component: TelaTemplatesPromptsDbComponent},
      {path: 'intencoes',  canActivate: [AuthLojaGuard], component: ListaIntencoesComponent},
      {path: 'templates-prompts/nova',  canActivate: [AuthLojaGuard], component: TelaTemplatesPromptsComponent},
      {path: 'templates-prompts/nova/:id',  canActivate: [AuthLojaGuard], component: TelaTemplatesPromptsComponent},
      {path: 'qrcodes',  canActivate: [AuthLojaGuard], component: ListagemQrcodeComponent},
      {path: 'qrcodes/adicionar',  canActivate: [AuthLojaGuard], component: CadQrcodeComponent},
      {path: 'qrcodes/edit/:id',  canActivate: [AuthLojaGuard], component: CadQrcodeComponent},
      {path: 'editor-template-notificacoes',  canActivate: [AuthLojaGuard], component: EditorTemplateNotificacoesComponent}
    ],
  },
  {
    path: 'assinatura/pagamento/:token', component: AssinaturaPagamentoComponent,
  },
  {
    path: 'assinatura/trocarcartao/:token', component: AssinaturaTrocarcartaoComponent
  },

  {
    path: 'assinatura/blackfriday/anual', component: AssinaturaBlackfridayComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FidelidadeRoutingModule { }


//export const routing: ModuleWithProviders = RouterModule.forChild(routes);
