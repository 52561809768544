<div class="page-title-right">
  <ol class="breadcrumb m-0">
    <li class="breadcrumb-item active">Fidelidade</li>
  </ol>
</div>

<div class="temas-container" *ngIf="empresa">
  <!-- Header -->
  <div class="temas-header">
    <div class="temas-header-content">
      <div>
        <h4 class="mb-2"><i class="fas fa-paint-roller mr-2"></i>Temas do Cardápio</h4>
        <p class="text-muted">Personalize a aparência do seu cardápio digital</p>
    </div>
      <button class="btn-novo-tema" (click)="criarTemaPersonalizado()">
        <i class="fas fa-plus-circle"></i>
        <span>Criar Tema Personalizado</span>
      </button>
    </div>
  </div>

  <!-- Navegação -->
  <div class="temas-nav">
    <button class="nav-item" [class.active]="filtroAtual === 'todos'" (click)="aplicarFiltro('todos')">
      <i class="fas fa-th-large"></i>
      <span>Todos</span>
    </button>
    <button class="nav-item" [class.active]="filtroAtual === 'personalizados'" (click)="aplicarFiltro('personalizados')">
      <i class="fas fa-palette"></i>
      <span>Personalizados</span>
    </button>
    <button class="nav-item" [class.active]="filtroAtual === 'comemorativos'" (click)="aplicarFiltro('comemorativos')">
      <i class="fas fa-calendar-alt"></i>
      <span>Datas Comemorativas</span>
    </button>
  </div>

  <!-- Grid de Temas -->
  <div class="temas-grid">
    <ng-container *ngFor="let tema of temasFiltrados">
      <div class="tema-item" [class.ativo]="isTemaAtivo(tema)">
        <!-- Preview do Tema -->
        <div class="tema-preview">
          <!-- Para temas personalizados -->
          <div class="cores-preview" *ngIf="tema.tipo === 'personalizado'">
            <div class="cor" [style.background-color]="tema.corFundoDoSite" kendoTooltip title="Cor de fundo do site"></div>
            <div class="cor" [style.background-color]="tema.corTextoPrimaria" title="Cor de texto primária" kendoTooltip></div>
            <div class="cor" [style.background-color]="tema.corTextoSecundaria" title="Cor de texto secundária" kendoTooltip></div>
            <div class="cor" [style.background-color]="tema.corBotao" title="Cor de botão" kendoTooltip></div>
            <div class="cor" [style.background-color]="tema.corTextoBotao" title="Cor de texto do botão" kendoTooltip></div>
            <div class="cor" [style.background-color]="tema.corFundo" title="Cor de fundo do topo e rodapé" kendoTooltip></div>
            <div class="cor" [style.background-color]="tema.corTexto" title="Cor do texto do topo e rodapé" kendoTooltip></div>
          </div>
          <!-- Para temas do sistema -->
          <img *ngIf="tema.tipo !== 'personalizado'"
               [src]="'/assets/loja/' + tema.imagem"
               [alt]="tema.nome"
               (error)="handleImageError($event, tema.imagem)">

          <!-- Indicador de tema ativo -->
          <div class="tema-status" *ngIf="isTemaAtivo(tema)">
            <i class="fas fa-check-circle"></i>
          </div>

          <!-- Badges -->
          <div class="tema-badge novo" *ngIf="tema.novo">Novo</div>
          <div class="tema-badge atualizado" *ngIf="tema.atualizado">Atualizado</div>
        </div>

        <!-- Informações do Tema -->
        <div class="tema-info">
          <h5>{{tema.nome}}</h5>
          <div class="tema-actions">
            <!-- Botão de editar para temas personalizados -->
            <button class="btn-acao editar" *ngIf="tema.tipo === 'personalizado'"
                    (click)="editarTema(tema)" title="Editar tema">
              <i class="fas fa-edit"></i>
            </button>

            <!-- Botões de ação -->
            <ng-container *ngIf="!isTemaAtivo(tema)">
              <button class="btn-acao ativar" [disabled]="ativando"
                      (click)="tema.tipo === 'personalizado' ? ativarTemaPersonalizado(tema) :
                              tema.codigo === 'meucardapioclassico' ? desativarTema() : ativarTema(tema.codigo)">
                Ativar
              </button>
            </ng-container>
            <div class="status-ativo" *ngIf="isTemaAtivo(tema)">
              Ativo
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>

<style>
.temas-container {
  padding: 0 24px;
  max-width: 1600px;
  margin: 0 auto;
}

.temas-header {
  margin-bottom: 24px;
  padding: 24px 0;
  border-bottom: 1px solid #e9ecef;
}

.temas-header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.btn-novo-tema {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px 24px;
  background: linear-gradient(45deg, #2196f3, #1976d2);
  border: none;
  border-radius: 8px;
  color: white;
  font-weight: 600;
    transition: all 0.3s ease;
}

.btn-novo-tema:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(33, 150, 243, 0.2);
}

.temas-nav {
  display: flex;
  gap: 16px;
  margin-bottom: 32px;
  padding: 0 16px;
}

.nav-item {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px 24px;
  background: transparent;
  border: none;
  border-radius: 8px;
  color: #6c757d;
  font-weight: 500;
  transition: all 0.3s ease;
}

.nav-item:hover {
  background: #f8f9fa;
  color: #2196f3;
}

.nav-item.active {
  background: #e3f2fd;
  color: #2196f3;
  font-weight: 600;
}

.temas-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 24px;
  padding: 16px;
}

.tema-item {
  background: white;
  border-radius: 8px;
  overflow: hidden;
  transition: all 0.2s ease;
  border: 2px solid #e9ecef;
}

.tema-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0,0,0,0.08);
}

.tema-item.ativo {
  border-color: #2e7d32;
  background-color: #f8f9fa;
  box-shadow: 0 4px 12px rgba(46, 125, 50, 0.12);
}

.tema-preview {
  position: relative;
  padding-top: 56.25%;
  background: #f8f9fa;
  overflow: hidden;
}

.tema-preview img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.cores-preview {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.cor {
  flex: 1;
  transition: all 0.3s ease;
}

.tema-status {
  position: absolute;
  top: 12px;
  right: 12px;
  width: 32px;
  height: 32px;
  background: #2e7d32;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  box-shadow: 0 2px 8px rgba(46, 125, 50, 0.3);
}

.tema-badge {
  position: absolute;
  top: 12px;
  left: 12px;
  padding: 6px 12px;
  border-radius: 20px;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.tema-badge.novo {
  background: #2196f3;
  color: white;
}

.tema-badge.atualizado {
  background: #4caf50;
  color: white;
}

.tema-info {
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.tema-info h5 {
  margin: 0;
  font-weight: 600;
}

.tema-actions {
  display: flex;
  gap: 8px;
  align-items: center;
  min-height: 36px;
}

.btn-acao {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  font-weight: 500;
  transition: all 0.2s ease;
}

.btn-acao.editar {
  background: #e3f2fd;
  color: #2196f3;
}

.btn-acao.editar:hover {
  background: #bbdefb;
}

.btn-acao.ativar {
  background: #e3f2fd;
  color: #2196f3;
  flex: 1;
}

.btn-acao.ativar:hover {
  background: #bbdefb;
}

.status-ativo {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  background: #e8f5e9;
  color: #2e7d32;
  padding: 6px 12px;
  border-radius: 20px;
  font-weight: 600;
  font-size: 13px;
  flex: 1;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.status-ativo i {
  font-size: 14px;
}

@media (max-width: 768px) {
  .temas-container {
    padding: 0 16px;
  }

  .temas-nav {
    overflow-x: auto;
    padding: 0 8px;
  }

  .nav-item {
    white-space: nowrap;
  }

  .temas-grid {
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
    gap: 16px;
    padding: 8px;
  }
}
</style>
